import { render, staticRenderFns } from "./brand_config_permissions.vue?vue&type=template&id=815ff300&scoped=true"
import script from "./brand_config_permissions.vue?vue&type=script&lang=js"
export * from "./brand_config_permissions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "815ff300",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2460310968/src/clients/ap3/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('815ff300')) {
      api.createRecord('815ff300', component.options)
    } else {
      api.reload('815ff300', component.options)
    }
    module.hot.accept("./brand_config_permissions.vue?vue&type=template&id=815ff300&scoped=true", function () {
      api.rerender('815ff300', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/users/brand_config_permissions.vue"
export default component.exports