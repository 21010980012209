<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Menu Scheduler</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout wrap>
        <v-flex>
          <div class="Body-1-Black-High-Emphasis-Left">
            Turn Menus On/Off
          </div>
          <v-flex xs12 display-flex wrap pl-0>
            <v-checkbox
              class="mt-0"
              style="max-width: 120px;"
              label="Read"
              :disabled="true"
              :input-value="true"
            />
            <v-checkbox
              class="mt-0"
              style="max-width: 120px;"
              label="Write"
              :value="customPermissions.toggleMenuEnabled"
              v-model="permissions.scopes"
              :disabled="!canGivetoggleMenuEnabled"
            />
          </v-flex>
        </v-flex>
        <v-flex>
          <div class="Body-1-Black-High-Emphasis-Left">
            Menu Scheduling
          </div>
          <v-flex xs12 display-flex wrap pl-0>
            <v-checkbox
              class="mt-0"
              style="max-width: 120px;"
              :value="customPermissions.allowReadSchedule"
              label="Read"
              v-model="permissions.scopes"
              :disabled="
                permissions.scopes.includes(customPermissions.allowSchedule) ||
                  !canGiveAllowReadSchedule
              "
            />
            <v-checkbox
              class="mt-0"
              style="max-width: 120px;"
              label="Write"
              :value="customPermissions.allowSchedule"
              :input-value="permissions.scopes"
              @change="toggleMenuScheduleAllowed"
              :disabled="!canGiveAllowSchedule"
            />
          </v-flex>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['permissions', 'selectedRole'],
  computed: {
    ...mapState('users', ['customPermissions']),
    ...mapGetters('adminPanel', ['isAdmin', 'hasSpecificPermissions']),
    canGivetoggleMenuEnabled() {
      return (
        this.isAdmin || this.hasSpecificPermissions([this.customPermissions.toggleMenuEnabled])
      );
    },
    canGiveAllowReadSchedule() {
      return (
        this.isAdmin || this.hasSpecificPermissions([this.customPermissions.allowReadSchedule])
      );
    },
    canGiveAllowSchedule() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.allowSchedule]);
    },
  },
  methods: {
    toggleMenuScheduleAllowed() {
      const allowSchedulePermissionIndex = this.permissions.scopes.indexOf(
        this.customPermissions.allowSchedule,
      );
      if (allowSchedulePermissionIndex === -1) {
        if (!this.permissions.scopes.includes(this.customPermissions.allowReadSchedule)) {
          this.permissions.scopes.push(this.customPermissions.allowReadSchedule);
        }
        this.permissions.scopes.push(this.customPermissions.allowSchedule);
      } else {
        this.permissions.scopes.splice(allowSchedulePermissionIndex, 1);
      }
    },
  },
};
</script>

<style scoped></style>
