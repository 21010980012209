import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(
        VCardTitle,
        { attrs: { "primary-title": "" } },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { staticClass: "pt-4 pl-4", attrs: { xs8: "" } },
                [
                  _c(VLayout, { staticClass: "H5-Primary-Left" }, [
                    _vm._v("CentricOS Global Brand Group Permissions")
                  ]),
                  _c(
                    VLayout,
                    [
                      _c(VTextField, {
                        staticClass: "Brand-Not-Selected-Error",
                        attrs: {
                          value:
                            _vm.selectedBrands.length ||
                            _vm.selectedGlobalMenuGroups.length
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                { staticStyle: { cursor: "pointer" }, attrs: { xs4: "" } },
                [
                  _vm.selectedGlobalMenuGroups.length > 0
                    ? _c(
                        VLayout,
                        {
                          staticClass: "mt-3 mr-5 noselect",
                          attrs: { "justify-end": "" },
                          on: {
                            click: function($event) {
                              _vm.isAllCollapsed = !_vm.isAllCollapsed
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "Body-2-Primary-On-Surface-Left" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.isAllCollapsed
                                      ? "Expand All"
                                      : "Collapse All"
                                  ) +
                                  "\n          "
                              )
                            ]
                          ),
                          _c(VIcon, { staticStyle: { color: "#0d73d8" } }, [
                            _vm._v(
                              _vm._s(
                                _vm.isAllCollapsed
                                  ? "mdi-chevron-down"
                                  : "mdi-chevron-up"
                              )
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "loading-wrapper",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            VLayout,
            { staticClass: "v-card-content" },
            [
              _c(
                VFlex,
                [
                  _vm.selectedGlobalMenuGroups.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            VLayout,
                            [
                              _c(
                                VFlex,
                                { attrs: { xs8: "" } },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Search GlobalMenuGroups",
                                      "prepend-icon": "mdi-magnify",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(VFlex, { attrs: { xs4: "" } })
                            ],
                            1
                          ),
                          _c(
                            VLayout,
                            { staticStyle: { "min-height": "450px" } },
                            [
                              _c(
                                VFlex,
                                _vm._l(
                                  _vm.orderBy(
                                    _vm.filterBy(
                                      _vm.selectedGlobalMenuGroups,
                                      _vm.search,
                                      "name"
                                    ),
                                    "name"
                                  ),
                                  function(globalMenuGroup, sIndex) {
                                    return _c(
                                      VLayout,
                                      {
                                        key: sIndex,
                                        staticClass:
                                          "GlobalMenuGroup-Permissions-Container px-3 mt-2 mb-2 noselect"
                                      },
                                      [
                                        _c(
                                          VFlex,
                                          [
                                            _c(
                                              VLayout,
                                              [
                                                _c(
                                                  VFlex,
                                                  {
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.expand(
                                                          globalMenuGroup
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(VLayout, [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "H6-Secondary-Left"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                globalMenuGroup.name ||
                                                                  "Untitled"
                                                              ) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c(
                                                      VLayout,
                                                      [
                                                        _c(VTextField, {
                                                          staticClass:
                                                            "Brand-Not-Selected-Error",
                                                          attrs: {
                                                            value: _vm.getSelectedBrands(
                                                              globalMenuGroup
                                                            ).length
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VFlex,
                                                  { attrs: { xs4: "" } },
                                                  [
                                                    _c(
                                                      VLayout,
                                                      {
                                                        attrs: {
                                                          "justify-end": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          VFlex,
                                                          [
                                                            _c(
                                                              VLayout,
                                                              {
                                                                attrs: {
                                                                  "justify-end":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  VBtn,
                                                                  {
                                                                    attrs: {
                                                                      flat: "",
                                                                      color:
                                                                        "#090c9b"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.removeGlobalMenuGroup(
                                                                          globalMenuGroup.id
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        attrs: {
                                                                          left:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-delete-outline"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "REMOVE\n                            "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VFlex,
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.expand(
                                                                  globalMenuGroup
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              VLayout,
                                                              {
                                                                staticStyle: {
                                                                  cursor:
                                                                    "pointer",
                                                                  "margin-top":
                                                                    "0px"
                                                                },
                                                                attrs: {
                                                                  "justify-center":
                                                                    ""
                                                                }
                                                              },
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .expansionPanelValues[
                                                                        globalMenuGroup
                                                                          .id
                                                                      ]
                                                                        ? "mdi-chevron-up"
                                                                        : "mdi-chevron-down"
                                                                    )
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            !_vm.expansionPanelValues[
                                              globalMenuGroup.id
                                            ]
                                              ? _c(
                                                  VLayout,
                                                  {
                                                    staticClass: "my-1",
                                                    staticStyle: {
                                                      cursor: "pointer"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.expand(
                                                          globalMenuGroup
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(VFlex, [
                                                      _vm._v(
                                                        "\n                      User has access to \n                      "
                                                      ),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            globalMenuGroup.totalSelected
                                                          ) +
                                                            "/" +
                                                            _vm._s(
                                                              globalMenuGroup.total
                                                            ) +
                                                            " brands"
                                                        )
                                                      ]),
                                                      _vm._v(
                                                        "\n                       at " +
                                                          _vm._s(
                                                            globalMenuGroup.name ||
                                                              "Untitled"
                                                          ) +
                                                          "\n                    "
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  VLayout,
                                                  {
                                                    staticClass: "pl-2",
                                                    attrs: {
                                                      "justify-start": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      VFlex,
                                                      [
                                                        _c(
                                                          VLayout,
                                                          {
                                                            staticStyle: {
                                                              "margin-top":
                                                                "-20px",
                                                              "margin-bottom":
                                                                "-15px"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.toggleBrandsSelection(
                                                                  globalMenuGroup.id
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(VCheckbox, {
                                                              attrs: {
                                                                label:
                                                                  "Select All",
                                                                value: true,
                                                                disabled: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .selectedGlobalMenuGroupsCache[
                                                                    globalMenuGroup
                                                                      .id
                                                                  ]
                                                                    .isAllSelected,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .selectedGlobalMenuGroupsCache[
                                                                      globalMenuGroup
                                                                        .id
                                                                    ],
                                                                    "isAllSelected",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "\n                            selectedGlobalMenuGroupsCache[globalMenuGroup.id].isAllSelected\n                          "
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VLayout,
                                                          [
                                                            _c(VTextField, {
                                                              staticClass:
                                                                "Brand-Not-Selected-Error",
                                                              attrs: {
                                                                rules: _vm.appSelectionRules(
                                                                  globalMenuGroup.totalSelected ||
                                                                    globalMenuGroup.isAllSelected
                                                                ),
                                                                value: Object.keys(
                                                                  _vm
                                                                    .selectedGlobalMenuGroupsCache[
                                                                    globalMenuGroup
                                                                      .id
                                                                  ].brands
                                                                )
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          VLayout,
                                                          [
                                                            _c(
                                                              VList,
                                                              _vm._l(
                                                                _vm.getBrands(
                                                                  globalMenuGroup.id
                                                                ),
                                                                function(
                                                                  brand,
                                                                  bIndex
                                                                ) {
                                                                  return _c(
                                                                    VListTile,
                                                                    {
                                                                      key: bIndex,
                                                                      attrs: {
                                                                        label:
                                                                          brand.name
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        VListTileAction,
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.toggleBrandSelection(
                                                                                    globalMenuGroup.id
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                VCheckbox,
                                                                                {
                                                                                  attrs: {
                                                                                    disabled:
                                                                                      "",
                                                                                    label:
                                                                                      "" +
                                                                                      brand.name
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm
                                                                                        .selectedGlobalMenuGroupsCache[
                                                                                        globalMenuGroup
                                                                                          .id
                                                                                      ]
                                                                                        .brands[
                                                                                        brand
                                                                                          .id
                                                                                      ]
                                                                                        .isSelected,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm
                                                                                          .selectedGlobalMenuGroupsCache[
                                                                                          globalMenuGroup
                                                                                            .id
                                                                                        ]
                                                                                          .brands[
                                                                                          brand
                                                                                            .id
                                                                                        ],
                                                                                        "isSelected",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "\n                                    selectedGlobalMenuGroupsCache[globalMenuGroup.id].brands[\n                                      brand.id\n                                    ].isSelected\n                                  "
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VLayout,
                            {
                              staticClass: "pl-4 mt-4 mb-2 mr-5",
                              attrs: { "justify-start": "" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "Body-2-Primary-On-Surface-Left noselect",
                                  staticStyle: {
                                    "text-decoration": "underline",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Edit Brand Group Permissions\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "Body-1-Primary-Left Permissions-Link",
                          on: {
                            click: function($event) {
                              _vm.dialog = true
                            }
                          }
                        },
                        [
                          _vm.selectedGlobalMenuGroups.length === 0
                            ? _c("div", [_vm._v("Choose Brand Groups")])
                            : _vm._e()
                        ]
                      ),
                  _c("permissions-dialog", {
                    attrs: {
                      dialog: _vm.dialog,
                      selectedGlobalMenuGroups:
                        _vm.selectedGlobalMenuGroupsCache
                    },
                    on: {
                      "update:dialog": function($event) {
                        _vm.dialog = $event
                      },
                      "update:selectedGlobalMenuGroups": function($event) {
                        _vm.selectedGlobalMenuGroupsCache = $event
                      },
                      "update:selected-global-menu-groups": function($event) {
                        _vm.selectedGlobalMenuGroupsCache = $event
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }