import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { "grid-list-xl": "" } },
    [
      _vm.dataFetched
        ? _c(
            VForm,
            {
              ref: "form",
              staticClass: "settings",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                VLayout,
                { staticClass: "mb-5", attrs: { row: "", wrap: "" } },
                [
                  _c(VFlex, { attrs: { xs8: "" } }, [_c("view-title")], 1),
                  !_vm.isNewUser && !_vm.isActiveUser && _vm.canEdit
                    ? _c(
                        VFlex,
                        { attrs: { xs4: "" } },
                        [
                          _c(VLayout, { attrs: { "justify-end": "" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "Remove-User-Btn mt-4",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteUser()
                                  }
                                }
                              },
                              [
                                _c(VIcon, { staticClass: "Remove-Icon" }, [
                                  _vm._v("mdi-delete-outline")
                                ]),
                                _vm._v("Delete User\n          ")
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c("user-details", {
                        attrs: {
                          isActiveUser: _vm.isActiveUser,
                          disabled: !_vm.canEdit
                        },
                        model: {
                          value: _vm.user,
                          callback: function($$v) {
                            _vm.user = $$v
                          },
                          expression: "user"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.isSmartAnalyticsUser
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("user-analytics", {
                            model: {
                              value: _vm.user,
                              callback: function($$v) {
                                _vm.user = $$v
                              },
                              expression: "user"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.renderPermissions
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("user-permissions", {
                            attrs: {
                              permissions: _vm.user.permissions,
                              selectedRole: _vm.selectedRole,
                              permissionsStatic: _vm.permissionsStatic,
                              selectedGroup: _vm.selectedGroup
                            },
                            on: {
                              "update:permissions": function($event) {
                                return _vm.$set(_vm.user, "permissions", $event)
                              },
                              "update:selectedGroup": function($event) {
                                _vm.selectedGroup = $event
                              },
                              "update:selected-group": function($event) {
                                _vm.selectedGroup = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.renderPermissions && _vm.isGlobalMenuGroupManager
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("centricOS-global-menu-group-permissions", {
                            attrs: {
                              permissions: _vm.user.permissions,
                              permissionsStatic: _vm.permissionsStatic
                            },
                            on: {
                              "update:permissions": function($event) {
                                return _vm.$set(_vm.user, "permissions", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.renderPermissions && _vm.isLocalMenuGroupManager
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("centricOS-local-menu-group-permissions", {
                            attrs: {
                              userId: _vm.user.id,
                              permissions: _vm.user.permissions,
                              permissionsStatic: _vm.permissionsStatic
                            },
                            on: {
                              "update:permissions": function($event) {
                                return _vm.$set(_vm.user, "permissions", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.renderMenuScheduler
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("menu-scheduler", {
                            attrs: {
                              permissions: _vm.user.permissions,
                              selectedRole: _vm.selectedRole
                            },
                            on: {
                              "update:permissions": function($event) {
                                return _vm.$set(_vm.user, "permissions", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedRole === _vm.UserRoles.im_user + "_role" ||
                  _vm.selectedRole === _vm.UserRoles.site_operator + "_role"
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("brand-config-permissions", {
                            attrs: {
                              permissions: _vm.user.permissions,
                              selectedRole: _vm.selectedRole
                            },
                            on: {
                              "update:permissions": function($event) {
                                return _vm.$set(_vm.user, "permissions", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedRole === _vm.UserRoles.im_user + "_role" ||
                  _vm.selectedRole === _vm.UserRoles.site_operator + "_role"
                    ? _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("announcement-config-permissions", {
                            attrs: {
                              permissions: _vm.user.permissions,
                              selectedRole: _vm.selectedRole
                            },
                            on: {
                              "update:permissions": function($event) {
                                return _vm.$set(_vm.user, "permissions", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("save-footer", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModified && _vm.canEdit,
                    expression: "isModified && canEdit"
                  }
                ],
                attrs: {
                  cancelAction: _vm.cancel,
                  saveLabel: _vm.isNewUser ? "Create User" : "Save Changes",
                  saveAction: _vm.save
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }