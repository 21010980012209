<template>
  <v-dialog v-model="dialogProxy" width="914">
    <v-card>
      <v-card-title primary-title style="display: block;">
        <div class="H4-Secondary-Center">Choose Sites</div>
        <div class="Body-2-Selected-On-Surface-Medium-Emphasis-Left">
          {{ getSubTitle() }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap justify-space-between>
          <v-flex xs12>
            <v-text-field
              label="Search Sites"
              prepend-icon="mdi-magnify"
              v-model="search"
              clearable
            />
          </v-flex>
          <v-flex xs12>
            <v-layout row>
              <v-flex
                :xs6="selectedRole !== `${UserRoles.runner}_role`"
                :xs12="selectedRole === `${UserRoles.runner}_role`"
                class="site-panel"
                style="overflow: hidden;"
              >
                <div class="H6-Secondary-Left">All Sites</div>
                <div>
                  <v-list class="list-panel" id="list-panel">
                    <v-list-tile
                      @click="selectAll"
                      v-if="selectedRole !== `${UserRoles.runner}_role`"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title>Select All</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-for="(site, siteIndex) of orderBy(
                        filterBy(allSites, search, 'name'),
                        'name',
                      )"
                      @click="() => {}"
                      :key="site.id"
                      :id="siteIndex"
                    >
                      <v-list-tile-action v-if="selectedRole === `${UserRoles.runner}_role`">
                        <v-radio-group mandatory multiple v-model="selectedSitesProxy[0]">
                          <v-radio
                            color="#4020ae"
                            v-if="isVisible(siteIndex)"
                            :value="site.id"
                            :label="site.name"
                          />
                        </v-radio-group>
                      </v-list-tile-action>
                      <v-list-tile-action v-else>
                        <v-checkbox
                          color="#4020ae"
                          v-if="isVisible(siteIndex)"
                          :value="site.id"
                          :label="site.name"
                          v-model="selectedSitesProxy"
                        />
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                </div>
              </v-flex>
              <v-flex xs6 v-if="selectedRole !== `${UserRoles.runner}_role`">
                <div class="H6-Secondary-Left">
                  Sites Selected ({{ selectedSitesProxy.length }})
                </div>
                <div>
                  <v-list class="list-panel">
                    <v-list-tile @click="removeAll">
                      <v-list-tile-content>
                        <v-list-tile-title>Remove All</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-for="site of orderBy(filterBy(selectedSitesData, search, 'name'), 'name')"
                      @click="removeSite(site.id)"
                      :key="site.id"
                    >
                      <v-list-tile-action>
                        <v-icon>mdi-close-circle</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ site.name }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="cancel()">Cancel</v-btn>
        <v-btn color="primary" flat @click="confirm()">Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import filters from 'vue2-filters';
import { mapState } from 'vuex';
import { UserRoles } from '@/constants';

export default {
  mixins: [filters.mixin],
  props: ['dialog', 'selectedSites', 'selectedRole', 'selectedGroup'],
  data: () => ({
    dialogProxy: false,
    selectedSitesProxy: [],
    search: null,
    visibleAreaTop: 0,
    visibleAreaBottom: 550,
    sitePanelDOMNode: null,
    saveData: false,
    siteDOMNodeHeight: 48,
    listHeight: 450,
    offset: 200,
    UserRoles,
  }),
  async mounted() {
    try {
      this.sitePanelDOMNode = document.getElementById('list-panel');
      this.sitePanelDOMNode.addEventListener('scroll', this.handleScroll);
    } catch (err) {
      console.error(err);
    }
  },
  beforeDestroy() {
    const listPanel = document.getElementById('list-panel');
    if (listPanel) listPanel.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('sites', ['siteMap']),
    selectedSitesData() {
      return this.selectedSitesProxy.map((e) => this.siteMap[e]) || [];
    },
    allSites() {
      return Object.keys(this.siteMap).map((siteId) => this.siteMap[siteId]) || [];
    },
  },
  watch: {
    dialogProxy(d) {
      this.$emit('update:dialog', d);
      // caching everything in hashtable
      if (this.saveData) {
        const selectedSites = {};
        this.selectedSitesData.forEach((site) => {
          if (this.selectedSites[site.id]) {
            selectedSites[site.id] = this.selectedSites[site.id];
            return;
          }
          const siteCache = site;
          selectedSites[site.id] = siteCache;
          selectedSites[site.id].isAllSelected = true;
          if (site.brands) {
            Object.keys(site.brands).forEach((brandId) => {
              site.brands[brandId].isSelected = true;
            });
          }
        });
        this.$emit('update:selectedSites', selectedSites);
      }
      this.saveData = false;
    },
    dialog(value) {
      this.$set(this, 'dialogProxy', value);
      // converting hashtable into array
      const selectedSites = Object.keys(this.selectedSites).map((siteId) => siteId) || [];
      this.$set(this, 'selectedSitesProxy', selectedSites);
    },
  },
  methods: {
    cancel() {
      this.dialogProxy = false;
      this.selectedSitesProxy = Object.keys(this.selectedSites).map((siteId) => siteId) || [];
    },
    confirm() {
      // Have to do this check because radio buttons model the data as a string since only 1 can be selcted
      if (typeof this.selectedSitesProxy === 'string') {
        this.selectedSitesProxy = [this.selectedSitesProxy];
      }
      this.saveData = true;
      this.dialogProxy = false;
    },
    removeSite(id) {
      this.selectedSitesProxy.splice(this.selectedSitesProxy.indexOf(id), 1);
    },
    selectAll() {
      this.search = null;
      this.$set(this, 'selectedSitesProxy', Object.keys(this.siteMap));
    },
    removeAll() {
      this.selectedSitesProxy = [];
    },
    handleScroll() {
      this.visibleAreaTop = this.sitePanelDOMNode.scrollTop - this.offset;
      this.visibleAreaBottom = this.visibleAreaTop + this.listHeight + this.offset;
    },
    isVisible(i) {
      return (
        (i + 1) * this.siteDOMNodeHeight >= this.visibleAreaTop &&
        (i + 1) * this.siteDOMNodeHeight <= this.visibleAreaBottom
      );
    },
    getSubTitle() {
      return this.selectedRole === `${UserRoles.runner}_role`
        ? 'Runners can only be assigned o one site.'
        : 'Select sites to edit brand permissions.';
    },
  },
};
</script>

<style>
.site-panel {
  min-width: 300px;
}
.list-panel {
  height: 50vh;
  overflow: scroll;
  border: solid 1px #757575;
  margin-right: 5px;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
