<template>
  <v-card>
    <v-card-title primary-title>
      <v-layout>
        <v-flex xs8 class="pt-4 pl-4">
          <v-layout class="H5-Primary-Left">CentricOS Global Brand Group Permissions</v-layout>
          <v-layout>
            <v-text-field
              class="Brand-Not-Selected-Error"
              :value="selectedBrands.length || selectedGlobalMenuGroups.length"
            />
          </v-layout>
        </v-flex>
        <v-flex xs4 style="cursor: pointer;">
          <v-layout
            v-if="selectedGlobalMenuGroups.length > 0"
            justify-end
            class="mt-3 mr-5 noselect"
            v-on:click="isAllCollapsed = !isAllCollapsed"
          >
            <div class="Body-2-Primary-On-Surface-Left">
              {{ isAllCollapsed ? 'Expand All' : 'Collapse All' }}
            </div>
            <v-icon style="color: #0d73d8;">{{
              isAllCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'
            }}</v-icon>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-title>
    <loading-wrapper :loading="loading">
      <v-layout class="v-card-content">
        <v-flex>
          <div v-if="selectedGlobalMenuGroups.length > 0">
            <v-layout>
              <v-flex xs8>
                <v-text-field
                  label="Search GlobalMenuGroups"
                  prepend-icon="mdi-magnify"
                  v-model="search"
                  clearable
                />
              </v-flex>
              <v-flex xs4 />
            </v-layout>
            <v-layout style="min-height: 450px;">
              <v-flex>
                <v-layout
                  v-for="(globalMenuGroup, sIndex) of orderBy(
                    filterBy(selectedGlobalMenuGroups, search, 'name'),
                    'name',
                  )"
                  v-bind:key="sIndex"
                  class="GlobalMenuGroup-Permissions-Container px-3 mt-2 mb-2 noselect"
                >
                  <v-flex>
                    <v-layout>
                      <v-flex style="cursor: pointer;" @click="expand(globalMenuGroup)">
                        <v-layout>
                          <div class="H6-Secondary-Left">
                            {{ globalMenuGroup.name || 'Untitled' }}
                          </div>
                        </v-layout>
                        <v-layout>
                          <v-text-field
                            class="Brand-Not-Selected-Error"
                            :value="getSelectedBrands(globalMenuGroup).length"
                          />
                        </v-layout>
                      </v-flex>
                      <v-flex xs4>
                        <v-layout justify-end>
                          <v-flex>
                            <v-layout justify-end>
                              <v-btn
                                flat
                                color="#090c9b"
                                v-on:click="removeGlobalMenuGroup(globalMenuGroup.id)"
                              >
                                <v-icon class="mr-1" left>mdi-delete-outline</v-icon>REMOVE
                              </v-btn>
                            </v-layout>
                          </v-flex>
                          <v-flex @click="expand(globalMenuGroup)">
                            <v-layout style="cursor: pointer; margin-top: 0px;" justify-center>
                              <v-icon>{{
                                expansionPanelValues[globalMenuGroup.id]
                                  ? 'mdi-chevron-up'
                                  : 'mdi-chevron-down'
                              }}</v-icon>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout
                      style="cursor: pointer;"
                      @click="expand(globalMenuGroup)"
                      class="my-1"
                      v-if="!expansionPanelValues[globalMenuGroup.id]"
                    >
                      <v-flex>
                        User has access to&nbsp;
                        <b
                          >{{ globalMenuGroup.totalSelected }}/{{
                            globalMenuGroup.total
                          }}&nbsp;brands</b
                        >
                        &nbsp;at {{ globalMenuGroup.name || 'Untitled' }}
                      </v-flex>
                    </v-layout>
                    <v-layout class="pl-2" v-else justify-start>
                      <v-flex>
                        <v-layout
                          v-on:click="toggleBrandsSelection(globalMenuGroup.id)"
                          style="margin-top: -20px; margin-bottom: -15px;"
                        >
                          <v-checkbox
                            label="Select All"
                            v-model="
                              selectedGlobalMenuGroupsCache[globalMenuGroup.id].isAllSelected
                            "
                            :value="true"
                            disabled
                          />
                        </v-layout>
                        <v-layout>
                          <v-text-field
                            class="Brand-Not-Selected-Error"
                            :rules="
                              appSelectionRules(
                                globalMenuGroup.totalSelected || globalMenuGroup.isAllSelected,
                              )
                            "
                            :value="
                              Object.keys(selectedGlobalMenuGroupsCache[globalMenuGroup.id].brands)
                            "
                          />
                        </v-layout>
                        <v-layout>
                          <v-list>
                            <v-list-tile
                              v-for="(brand, bIndex) of getBrands(globalMenuGroup.id)"
                              v-bind:key="bIndex"
                              :label="brand.name"
                            >
                              <v-list-tile-action>
                                <div v-on:click="toggleBrandSelection(globalMenuGroup.id)">
                                  <v-checkbox
                                    v-model="
                                      selectedGlobalMenuGroupsCache[globalMenuGroup.id].brands[
                                        brand.id
                                      ].isSelected
                                    "
                                    disabled
                                    :label="`${brand.name}`"
                                  />
                                </div>
                              </v-list-tile-action>
                            </v-list-tile>
                          </v-list>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout justify-start class="pl-4 mt-4 mb-2 mr-5">
              <div
                class="Body-2-Primary-On-Surface-Left noselect"
                style="text-decoration: underline; cursor: pointer;"
                v-on:click="dialog = true"
              >
                Edit Brand Group Permissions
              </div>
            </v-layout>
          </div>
          <div v-else class="Body-1-Primary-Left Permissions-Link" v-on:click="dialog = true">
            <div v-if="selectedGlobalMenuGroups.length === 0">Choose Brand Groups</div>
          </div>
          <permissions-dialog
            :dialog.sync="dialog"
            :selectedGlobalMenuGroups.sync="selectedGlobalMenuGroupsCache"
          />
        </v-flex>
      </v-layout>
    </loading-wrapper>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import filters from 'vue2-filters';
import cloneDeep from 'lodash/cloneDeep';
import PermissionsDialog from './globalMenuGroupsSelectorDialog.vue';
import { MenuPermissionTypes, GlobalMenuGroupPermissions } from '../../../constants';

export default {
  mixins: [filters.mixin],
  components: {
    PermissionsDialog,
  },
  props: ['permissions', 'permissionsStatic'],
  data: () => ({
    dialog: false,
    selectedGlobalMenuGroupsCache: {},
    search: '',
    brandDescMap: {},
    isAllCollapsed: true,
    permissionScopesProxy: [],
    expansionPanelValues: {},
    loading: false,
    error: false,
    globalMenuGroupMap: {},
    brandMap: {},
    appSelectionRules: (n) => [() => !!n || 'Please select at least 1 brand'],
    permissionRules: (n) => [() => !!n || 'Please select at least 1 brand'],
  }),
  computed: {
    selectedGlobalMenuGroups() {
      const globalMenuGroups =
        Object.keys(this.selectedGlobalMenuGroupsCache).map((globalMenuGroupId) => {
          let totalSelected = 0;
          let total = 0;
          const globalMenuGroup = this.selectedGlobalMenuGroupsCache[globalMenuGroupId];
          Object.keys(globalMenuGroup.brands).forEach((b) => {
            if (globalMenuGroup.brands[b].isSelected) totalSelected += 1;
            total += 1;
          });
          return { ...globalMenuGroup, totalSelected, total };
        }) || [];
      return globalMenuGroups;
    },
    selectedBrands() {
      try {
        return this.selectedGlobalMenuGroups.map((s) => s.brands && Object.keys(s.brands)).flat();
      } catch {
        return [];
      }
    },
  },
  watch: {
    isAllCollapsed(c) {
      Object.keys(this.selectedGlobalMenuGroupsCache).forEach((s) => {
        this.$set(this.expansionPanelValues, s, !c);
      });
    },
    selectedGlobalMenuGroups(s) {
      const extraPermissions = this.permissions.scopes.filter(
        (scope) => !scope.includes(`:${MenuPermissionTypes.globalMenuGroup}:`),
      );
      const scopes = [];
      s.forEach((globalMenuGroup) => {
        GlobalMenuGroupPermissions.forEach((permission) =>
          scopes.push(permission.replace('{{id}}', globalMenuGroup.id)),
        );
      });
      scopes.push(...extraPermissions);
      // since we filtered it out previously
      scopes.push(`read:${MenuPermissionTypes.globalMenuGroup}:*`);
      scopes.sort();
      this.$emit('update:permissions', { scopes });
    },
    permissionsStatic(p) {
      // do the backwards mapping
      if (Object.keys(this.globalMenuGroupMap).length !== 0) {
        this.mapPropsToCache(p);
      }
    },
  },
  methods: {
    ...mapActions('centricOSMenu', ['getMenuGlobalMenuGroup', 'getMenuBrand']),
    async expand(globalMenuGroup) {
      this.$set(
        this.expansionPanelValues,
        globalMenuGroup.id,
        !this.expansionPanelValues[globalMenuGroup.id],
      );
    },
    async removeGlobalMenuGroup(id) {
      const globalMenuGroup = this.selectedGlobalMenuGroupsCache[id];
      const globalMenuGroupName = globalMenuGroup.name || 'Untitled';
      const input = await this.$confirm({
        title: 'Remove GlobalMenuGroup from Permissions?',
        message: `Are you sure you want to remove ${globalMenuGroupName} from this user's globalMenuGroup permission?`,
        buttonTrueText: 'REMOVE',
        buttonFalseText: 'CANCEL',
      });
      if (input) {
        this.selectedGlobalMenuGroupsCache[id] = undefined;
        delete this.selectedGlobalMenuGroupsCache[id];
      }
    },
    getBrands(globalMenuGroupId) {
      const globalMenuGroup = this.selectedGlobalMenuGroupsCache[globalMenuGroupId];
      const { brands } = globalMenuGroup;
      return Object.values(brands) || [];
    },
    toggleBrandSelection(globalMenuGroupId) {
      const globalMenuGroup = this.selectedGlobalMenuGroupsCache[globalMenuGroupId];
      this.setGlobalMenuGroup(globalMenuGroup.id, { ...globalMenuGroup, isAllSelected: false });
    },
    toggleBrandsSelection(globalMenuGroupId) {
      const globalMenuGroup = this.selectedGlobalMenuGroupsCache[globalMenuGroupId];
      const { brands } = globalMenuGroup;
      Object.keys(brands).forEach((b) => {
        const brand = brands[b];
        this.setBrand(globalMenuGroup.id, { ...brand, isSelected: globalMenuGroup.isAllSelected });
      });
    },
    setBrand(globalMenuGroupId, brand) {
      const globalMenuGroup = this.selectedGlobalMenuGroupsCache[globalMenuGroupId];
      this.$set(this.selectedGlobalMenuGroupsCache, globalMenuGroupId, {
        ...globalMenuGroup,
        brands: {
          ...globalMenuGroup.brands,
          [brand.id]: {
            ...brand,
          },
        },
      });
    },
    setGlobalMenuGroup(globalMenuGroupId, globalMenuGroup) {
      this.$set(this.selectedGlobalMenuGroupsCache, globalMenuGroupId, globalMenuGroup);
    },
    mapPropsToCache(p) {
      // do the backwards mapping
      const selectedGlobalMenuGroupsCache = {};
      if (p.scopes && Array.isArray(p.scopes)) {
        p.scopes.sort().forEach((s) => {
          const roleInfo = s.split(':');
          const permissionType = roleInfo[1];
          const id = roleInfo[2];
          if (permissionType === MenuPermissionTypes.globalMenuGroup && id !== '*') {
            if (!selectedGlobalMenuGroupsCache[id])
              selectedGlobalMenuGroupsCache[id] = cloneDeep(this.globalMenuGroupMap[id]);
            selectedGlobalMenuGroupsCache[id].isAllSelected = true;
            const { brands } = selectedGlobalMenuGroupsCache[id];
            Object.keys(brands).forEach((b) => {
              brands[b].isSelected = true;
            });
          } else if (permissionType === MenuPermissionTypes.brand && id !== '*') {
            // find corresponding globalMenuGroup
            const globalMenuGroupId = this.brandMap[id].global_menu_group.id;
            if (!selectedGlobalMenuGroupsCache[globalMenuGroupId]) {
              selectedGlobalMenuGroupsCache[globalMenuGroupId] = cloneDeep(
                this.globalMenuGroupMap[globalMenuGroupId],
              );
              Object.keys(selectedGlobalMenuGroupsCache[globalMenuGroupId].brands).forEach(
                (brandId) => {
                  selectedGlobalMenuGroupsCache[globalMenuGroupId].brands[
                    brandId
                  ].isSelected = false;
                },
              );
            }
            if (!selectedGlobalMenuGroupsCache[globalMenuGroupId].brands)
              selectedGlobalMenuGroupsCache[globalMenuGroupId].brands = {};
            selectedGlobalMenuGroupsCache[globalMenuGroupId].brands[id] = cloneDeep({
              ...this.brandMap[id],
              isSelected: true,
            });
          }
        });
      }
      Object.keys(selectedGlobalMenuGroupsCache).forEach((s) => {
        selectedGlobalMenuGroupsCache[s].isAllSelected =
          p.scopes.find((scope) => scope.includes(`group:${s}`)) &&
          Object.values(selectedGlobalMenuGroupsCache[s].brands).every((b) => b.isSelected);
      });
      this.$set(this, 'selectedGlobalMenuGroupsCache', selectedGlobalMenuGroupsCache);
    },
    getSelectedBrands(globalMenuGroup) {
      try {
        return Object.values(globalMenuGroup.brands);
      } catch {
        return [];
      }
    },
    hasAllGlobalMenuGroupsInfo() {
      return !(this.all_globalMenuGroups === null);
    },
    getAllowedID(permissions, type) {
      const ids = [];
      permissions.scopes.forEach((permission) => {
        const roleInfo = permission.split(':');
        const permissionType = roleInfo[1];
        const id = roleInfo[2];
        if (permissionType === type && id !== '*') ids.push(id);
      });
      return ids;
    },
    async getGlobalMenuGroups(permissions) {
      if (!permissions.scopes || !Array.isArray(permissions.scopes)) return;

      const group_ids = this.getAllowedID(permissions, MenuPermissionTypes.globalMenuGroup);
      const brand_ids = this.getAllowedID(permissions, MenuPermissionTypes.brand);
      const global_menu_groups = (
        await Promise.all(
          group_ids.map((id) =>
            this.getMenuGlobalMenuGroup({ id, params: { relationships: '["draft_brands"]' } }),
          ),
        )
      ).map((global_menu_group) => {
        global_menu_group = { ...global_menu_group, brands: {} };
        if (global_menu_group.draft_brands) {
          global_menu_group.draft_brands.forEach((brand) => {
            global_menu_group.brands[`${brand.id}`] = brand;
          });
        }
        return global_menu_group;
      });

      const global_menu_group_brands = global_menu_groups
        .map((group) => group?.draft_brands)
        .flat(1);
      const group_brand_ids = global_menu_group_brands.map((brand) => brand.id);
      global_menu_group_brands.forEach((brand) => {
        if (brand) this.brandMap[`${brand.id}`] = brand;
      });
      const filtered_brand_ids = brand_ids.filter(
        (brand_id) => !group_brand_ids.includes(brand_id),
      );
      const brands = await Promise.all(
        filtered_brand_ids.map((id) =>
          this.getMenuBrand({
            id,
            params: { relationships: '["global_menu_group.draft_brands"]' },
          }),
        ),
      );

      const global_menu_groups_from_brand = brands
        .map((brand) => brand.global_menu_group)
        .flat(1)
        .map((group) => {
          group = { ...group, brands: {} };
          if (group.draft_brands) {
            group.draft_brands.forEach((brand) => {
              group.brands[`${brand.id}`] = brand;
            });
          }
          return group;
        });

      const all_groups = global_menu_groups.concat(global_menu_groups_from_brand);
      all_groups.forEach((group) => {
        this.globalMenuGroupMap[`${group.id}`] = group;
      });
      brands.forEach((brand) => {
        if (brand) this.brandMap[`${brand.id}`] = brand;
      });
    },
  },

  async mounted() {
    this.loading = true;
    await this.getGlobalMenuGroups(this.permissionsStatic);
    if (
      Object.keys(this.globalMenuGroupMap).length !== 0 &&
      Object.keys(this.brandMap).length !== 0
    ) {
      this.mapPropsToCache(this.permissionsStatic);
    }
    this.loading = false;
  },
};
</script>

<style>
.Permissions-Link {
  text-decoration: underline;
  cursor: pointer;
}
.GlobalMenuGroup-Permissions-Container {
  border-bottom: 1px solid lightgrey;
}

.Brand-Not-Selected-Error {
  padding: 0 !important;
}

.Brand-Not-Selected-Error > .v-input__control > .v-input__slot {
  display: none !important;
}
</style>
