<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Brand Config</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout wrap>
        <v-flex>
          <v-flex xs12 display-flex wrap pl-0>
            <v-checkbox
              class="mt-0"
              label="Allow Menu Assignation"
              :value="customPermissions.assignBrandMenus"
              v-model="permissions.scopes"
              :disabled="!canGiveAssignBrandMenus"
            />
          </v-flex>
        </v-flex>
        <v-flex>
          <v-flex xs12 display-flex wrap pl-0>
            <v-checkbox
              class="mt-0"
              label="Allow Timeslot Modification"
              :value="customPermissions.editBrandTimeslots"
              v-model="permissions.scopes"
              :disabled="!canGiveEditBrandTimeslots"
            />
          </v-flex>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['permissions', 'selectedRole'],
  computed: {
    ...mapState('users', ['customPermissions']),
    ...mapGetters('adminPanel', ['isAdmin', 'hasSpecificPermissions']),
    canGiveAssignBrandMenus() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.assignBrandMenus]);
    },
    canGiveEditBrandTimeslots() {
      return (
        this.isAdmin || this.hasSpecificPermissions([this.customPermissions.editBrandTimeslots])
      );
    },
  },
};
</script>

<style scoped></style>
