<template>
  <v-container grid-list-xl>
    <v-form ref="form" lazy-validation class="settings" v-model="valid" v-if="dataFetched">
      <v-layout row wrap class="mb-5">
        <v-flex xs8>
          <view-title />
        </v-flex>
        <v-flex xs4 v-if="!isNewUser && !isActiveUser && canEdit">
          <v-layout justify-end>
            <div class="Remove-User-Btn mt-4" v-on:click="deleteUser()">
              <v-icon class="Remove-Icon">mdi-delete-outline</v-icon>Delete User
            </div>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <user-details v-model="user" :isActiveUser="isActiveUser" :disabled="!canEdit" />
        </v-flex>
        <v-flex v-if="isSmartAnalyticsUser" xs12>
          <user-analytics v-model="user" />
        </v-flex>
        <v-flex xs12 v-if="renderPermissions">
          <user-permissions
            :permissions.sync="user.permissions"
            :selectedRole="selectedRole"
            :permissionsStatic="permissionsStatic"
            :selectedGroup.sync="selectedGroup"
          />
        </v-flex>
        <v-flex xs12 v-if="renderPermissions && isGlobalMenuGroupManager">
          <centricOS-global-menu-group-permissions
            :permissions.sync="user.permissions"
            :permissionsStatic="permissionsStatic"
          />
        </v-flex>
        <v-flex xs12 v-if="renderPermissions && isLocalMenuGroupManager">
          <centricOS-local-menu-group-permissions
            :userId="user.id"
            :permissions.sync="user.permissions"
            :permissionsStatic="permissionsStatic"
          />
        </v-flex>
        <v-flex xs12 v-if="renderMenuScheduler">
          <menu-scheduler :permissions.sync="user.permissions" :selectedRole="selectedRole" />
        </v-flex>
        <v-flex
          xs12
          v-if="
            selectedRole === `${UserRoles.im_user}_role` ||
              selectedRole === `${UserRoles.site_operator}_role`
          "
        >
          <brand-config-permissions
            :permissions.sync="user.permissions"
            :selectedRole="selectedRole"
          />
        </v-flex>
        <v-flex
          xs12
          v-if="
            selectedRole === `${UserRoles.im_user}_role` ||
              selectedRole === `${UserRoles.site_operator}_role`
          "
        >
          <announcement-config-permissions
            :permissions.sync="user.permissions"
            :selectedRole="selectedRole"
          />
        </v-flex>
      </v-layout>
      <save-footer
        v-show="isModified && canEdit"
        :cancelAction="cancel"
        :saveLabel="isNewUser ? 'Create User' : 'Save Changes'"
        :saveAction="save"
      />
    </v-form>
  </v-container>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import { mapGetters, mapState } from 'vuex';
import { UserRoles, CustomPermissions, Realms, MenuRoles } from '@/constants';
import UserDetails from './details';
import UserAnalytics from './analytics';
import UserPermissions from './permissions/index';
import CentricOSGlobalMenuGroupPermissions from './centricOS-global-menu-group-permissions/index';
import CentricOSLocalMenuGroupPermissions from './centricOS-local-menu-group-permissions/index';
import MenuScheduler from './menu_scheduler';
import BrandConfigPermissions from './brand_config_permissions';
import AnnouncementConfigPermissions from './announcement_config_permissions';

export default {
  name: 'UserSettings',
  components: {
    UserDetails,
    UserAnalytics,
    UserPermissions,
    MenuScheduler,
    BrandConfigPermissions,
    AnnouncementConfigPermissions,
    CentricOSGlobalMenuGroupPermissions,
    CentricOSLocalMenuGroupPermissions,
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isModified) {
      const input = await this.$confirm({
        title: 'Leave without saving?',
        message: 'Changes will be lost if you do not save.',
        buttonTrueText: 'LEAVE',
        buttonFalseText: 'cancel',
      });
      if (input) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  data: () => ({
    backup: { name: {}, permissions: { scopes: [] } },
    user: { name: {}, permissions: { scopes: [] } },
    valid: true,
    dataFetched: false,
    realmId: Realms.cdl,
    permissionsStatic: { scopes: [] },
    UserRoles,
    selectedGroup: '',
  }),
  watch: {
    selectedRole() {
      this.permissionsStatic = this.user.permissions;
      this.realmId = this.selectedRole === 'runner_role' ? Realms.bolter : Realms.cdl;
    },
  },
  computed: {
    ...mapState('adminPanel', ['active_user_id']),
    ...mapState('sites', ['multigroups']),
    ...mapState('users', ['customPermissions']),
    ...mapGetters('adminPanel', ['isImUser', 'isAdmin', 'hasSpecificPermissions']),
    renderPermissions() {
      return (
        this.selectedRole === `${UserRoles.site_operator}_role` ||
        this.selectedRole === `${UserRoles.im_user}_role` ||
        this.selectedRole === `${UserRoles.runner}_role`
      );
    },
    renderMenuScheduler() {
      return (
        this.selectedRole === `${UserRoles.site_operator}_role` ||
        this.selectedRole === `${UserRoles.im_user}_role`
      );
    },
    hasAllSitesInfo() {
      return !(this.all_sites === null);
    },
    selectedRole() {
      try {
        return (
          this.user.permissions.scopes
            .map((p) => p.split(':')[0])
            .find((s) => s.indexOf('_role') > -1) || null
        );
      } catch (err) {
        return null;
      }
    },
    userSorted() {
      const newUser = this.user;
      newUser.permissions.scopes.sort();
      return newUser;
    },
    isModified() {
      return !isEqual(this.backup, this.userSorted);
    },
    isPermissionsModified() {
      if (!this.user || !this.backup) return false;
      return !isEqual(this.backup.permissions, this.userSorted.permissions);
    },
    isNewUser() {
      return !this.userId;
    },
    isSmartAnalyticsUser() {
      return this.hasSpecificPermissions([CustomPermissions.readAnalytics]);
    },
    isGlobalMenuGroupManager() {
      return this.user.permissions.scopes.some((scope) =>
        scope.includes(MenuRoles.global_menu_group_manager),
      );
    },
    isLocalMenuGroupManager() {
      return this.user.permissions.scopes.some((scope) =>
        scope.includes(MenuRoles.local_menu_group_manager),
      );
    },
    userName() {
      const { first, last } = this.user.name;
      return `${first} ${last}`;
    },
    userId() {
      try {
        return this.$route.params.user_id;
      } catch (err) {
        return null;
      }
    },
    isActiveUser() {
      return !!this.$route.params.user_id && this.$route.params.user_id === this.active_user_id;
    },
    canEdit() {
      return this.isAdmin || (this.isImUser && (this.renderPermissions || this.isNewUser));
    },
  },
  async mounted() {
    this.$store.dispatch('adminPanel/setLoading', true);
    try {
      await this.$store.dispatch('sites/fetchAll');

      if (!this.hasAllSitesInfo) {
        throw new Error('Could not fetch data');
      }

      if (this.isNewUser) {
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 1,
          title: 'Add a New User',
          to: { name: 'user-new' },
        });
        this.dataFetched = true;
      } else {
        await this.fetchData();
        this.$store.commit('adminPanel/setViewTitle', {
          depth: 1,
          title: this.userName,
          to: {
            name: 'user-settings',
            params: {
              user_id: this.$route.params.user_id,
            },
          },
        });
      }
    } catch (err) {
      console.error({ err });
      this.$toast.error('Could not fetch data');
      this.$router.push({ name: 'users-listing' });
      this.$store.commit('adminPanel/setViewTitle', {
        depth: 0,
        title: 'Users',
        to: { name: 'users-listing' },
      });
    }
    this.$store.dispatch('adminPanel/setLoading', false);
  },
  methods: {
    async save() {
      const v = this.$refs.form.validate();
      if (!this.valid || !v) {
        this.$toast('The form is not yet complete, please fix before saving');
        return;
      }

      let error = false;

      // clear out
      try {
        let userId = this.userId || null;
        this.user.permissions.scopes = this.user.permissions.scopes.filter((e) => e !== '*:*:*');
        this.user.permissions.scopes = this.user.permissions.scopes.filter(
          (e) => e !== `${UserRoles.site_operator}_role:*:*`,
        );
        const runner_role = this.user.permissions.scopes.find(
          (item) => typeof item === 'string' && item.indexOf('runner_role') > -1,
        );

        if (runner_role) {
          this.user.permissions.scopes.push('read:order:*');
          this.user.location_group = this.selectedGroup;
        }
        // need to store here since userId comes from $router (can error out on component mount)
        let user_id = this.userId;
        if (this.isNewUser) {
          userId = (
            await this.api.post('/user', {
              ...this.user,
              realm: this.realmId,
              password: this.getPassword(),
            })
          ).data.id;
          user_id = userId;
        } else {
          const res = await this.api.put(`/user/${this.userId}`, this.user);
          // In the event you switch from bolter to cdl realm or vice versa, the user Id changes for subsequent calls
          user_id = res.data.id;
        }

        if (this.isNewUser || this.isPermissionsModified) {
          await this.api.put(`/user/${user_id}/permissions`, {
            permissions: this.user.permissions,
          });
          // Have to use old id since cache has it and not new realm id
          this.clearPermissionsCache(userId);
        }
        if (this.isNewUser) {
          // send email for new user to set the password.
          await this.api.post(`/user/forgotpassword?realm=${this.realmId}`, {
            email: this.user.email,
            type: 'new_user',
          });
        }

        this.$set(this, 'backup', this.user);
      } catch (e) {
        console.error(e);
        error = e;
        if (e && e.response && e.response.data.code === 409) {
          this.$toast.error('Email address already exists');
        } else {
          this.$toast.error('Unable to save user data');
        }
      }

      if (!error) {
        if (this.isNewUser) this.$toast(`${this.userName} has been added`);
        else this.$toast('User data saved');

        this.$router.push({
          name: 'users-listing',
        });
      } else if (error && error.response && error.response.status === 409) {
        this.$toast.error('Email address already exists');
      } else {
        this.$toast.error('Unable to save user information');
      }
    },
    cancel() {
      // restore backups
      this.user = cloneDeep(this.backup);
      this.permissionsStatic = cloneDeep(this.user.permissions);
      this.$refs.form.resetValidation();
      this.$toast('All your changes have been reset');
    },
    async fetchData() {
      let userDetails = null;
      try {
        userDetails = (await this.api.get(`/user/${this.userId}/permissions?nocache=1`)).data;
      } catch (err) {
        console.error(err);
        this.$toast.error('Error retrieving user data');
        this.$router.push('/users');
      } finally {
        if (userDetails) {
          if (!(userDetails.permissions && userDetails.permissions.scopes)) {
            userDetails.permissions = { scopes: [] };
          }
          userDetails.permissions.scopes.sort();
          this.user = userDetails;
          this.permissionsStatic = userDetails.permissions;
        }
        this.dataFetched = true;
        this.backup = cloneDeep(this.user);
      }
    },
    async clearPermissionsCache(userId) {
      this.multigroups.forEach((multigroup) =>
        this.$store.dispatch('sites/fetchMultigroupExpanded', {
          id: multigroup.id,
          nocache: 1,
          userId,
        }),
      );
    },
    async deleteUser() {
      const input = await this.$confirm({
        title: 'Delete User',
        message: `Deleting will remove ${this.userName}'s information and access to Admin Panel. Are you sure you want to delete?`,
        buttonTrueText: 'DELETE',
        buttonFalseText: 'CANCEL',
      });

      if (input) {
        let error = false;
        try {
          await this.api.delete(`/user/${this.userId}`);
        } catch (err) {
          error = true;
        }
        if (!error) {
          this.$router.push({
            name: 'users-listing',
          });
          this.$toast.success(`${this.userName} has been removed`);
        } else {
          this.$toast.error(`Unable to remove ${this.userName}`);
        }
      }
    },
    userHasRole(user, role = '') {
      try {
        const permissions = user.permissions.scopes;
        return permissions.some((p) => p.indexOf(`${role}_role`) > -1);
      } catch (err) {
        return false;
      }
    },
    // This is used in case of admin being created (they have a longer password length requirement)
    getRandomPadding() {
      return Math.random()
        .toString(36)
        .replace('0.', '');
    },
    getPassword() {
      const randLetter = Math.trunc(Math.random() * 25 + 1);
      const randUpperCase = String.fromCharCode(randLetter + 65);
      // Adding two paddings to account for admin password length requirement
      const padding = `${this.getRandomPadding()}${this.getRandomPadding()}`;

      return `${randUpperCase}${padding}`;
    },
  },
};
</script>

<style scoped>
.Remove-User-Btn {
  height: 32px;
  width: 155px;
  font-family: Avenir;
  border-radius: 5px;
  font-size: 22.3px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  color: #ea526f;
  cursor: pointer;
}

.Remove-User-Btn:focus,
.Remove-User-Btn:hover {
  background-color: rgba(234, 82, 111, 0.2);
}

.Remove-Icon {
  color: #ea526f;
  margin-bottom: 4px;
}
</style>
