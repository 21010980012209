<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Announcements</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout wrap>
        <v-flex>
          <v-flex xs12 display-flex wrap pl-0>
            <v-checkbox
              class="mt-0"
              label="Allow Access to Announcements"
              :value="customPermissions.assignAnnouncement"
              v-model="permissions.scopes"
              :disabled="!isAdmin"
            />
          </v-flex>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['permissions', 'selectedRole'],
  computed: {
    ...mapState('users', ['customPermissions']),
    ...mapGetters('adminPanel', ['isAdmin']),
  },
};
</script>
