<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">Smart Analytics</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout>
        <v-flex xs12>
          <v-checkbox
            label="Has access to Feature"
            value="read:analytics:*"
            v-model="value.permissions.scopes"
          />
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ['value'],
};
</script>
