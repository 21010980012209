<template>
  <v-card>
    <v-card-title primary-title>
      <div class="H5-Primary-Left">User Details</div>
    </v-card-title>
    <div class="v-card-content">
      <v-layout>
        <v-flex xs7>
          <v-select
            :items="roles"
            label="Role"
            :value="selectedRole"
            placeholder="Please Select Role"
            v-on:input="selectRole"
            :rules="roleSelectionRules"
            :disabled="isActiveUser || disabled"
          />
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout>
        <v-flex xs7>
          <v-text-field
            v-model="value.name.first"
            label="First Name"
            :rules="nameRules"
            :disabled="disabled"
          />
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout>
        <v-flex xs7>
          <v-text-field
            v-model="value.name.last"
            label="Last Name"
            :rules="nameRules"
            :disabled="disabled"
          />
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout>
        <v-flex xs7>
          <v-text-field
            v-model="value.email"
            label="E-mail"
            :rules="emailRules"
            :disabled="disabled"
          />
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout>
        <v-flex xs7>
          <v-layout>
            <v-flex xs7>
              <v-text-field
                v-model="phone"
                label="Phone Number"
                mask="phone"
                :disabled="disabled"
              />
            </v-flex>
            <v-flex xs5 />
          </v-layout>
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout v-if="selectedRole === `${UserRoles.site_operator}_role` || isAdminRoleSelected">
        <v-flex xs7>
          <v-select
            :items="menuRoles"
            label="Menu Role"
            :value="selectedMenuRole"
            placeholder="Please Select Role"
            v-on:input="selectMenuRole"
            :disabled="isActiveUser || disabled"
          />
        </v-flex>
        <v-flex xs5 />
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <v-checkbox
            v-if="selectedRole === `${UserRoles.admin}_role`"
            label="View Payment IDs"
            :value="customPermissions.viewPaymentInfo"
            :disabled="!canGiveViewPaymentIDs || disabled"
            v-model="value.permissions.scopes"
          />
          <v-checkbox
            v-if="selectedRole === `${UserRoles.site_operator}_role`"
            label="Allow Manage Drop-Off Locations"
            :value="customPermissions.canWriteDeliverLocations"
            :disabled="!canGiveWriteDeliveryDestinations || disabled"
            v-model="value.permissions.scopes"
          />
          <v-checkbox
            v-if="selectedRole !== `${UserRoles.runner}_role`"
            label="Allow Refunds"
            :value="customPermissions.refundOrders"
            :disabled="!canGiveRefunds || disabled"
            v-model="value.permissions.scopes"
          />
          <v-checkbox
            label="Allow Menu Kick-Outs"
            :value="customPermissions.kickMenu"
            v-if="isAdminRoleSelected"
            :disabled="!canGiveMenuKick || disabled"
            v-model="value.permissions.scopes"
          />
          <v-checkbox
            v-if="selectedRole !== `${UserRoles.runner}_role`"
            label="Allow CentricOS Reports Access"
            :value="customPermissions.canAccessReports"
            :disabled="!canAccessCentricOSReports || disabled"
            v-model="value.permissions.scopes"
          />
          <v-checkbox
            v-if="selectedRole === `${UserRoles.admin}_role`"
            label="Allow Station Deletion"
            :value="customPermissions.canDeleteStations"
            v-model="value.permissions.scopes"
            :disabled="!canAllowStationDeletion || disabled"
          />
          <template
            v-if="
              selectedRole === `${UserRoles.site_operator}_role` ||
                selectedRole === `${UserRoles.im_user}_role`
            "
          >
            <v-checkbox
              label="Menu Access"
              :value="customPermissions.menuAccess"
              v-model="value.permissions.scopes"
              :disabled="!canGiveMenuAccess || disabled"
            />
            <v-checkbox
              label="Allow Renaming in Local Menus"
              :value="customPermissions.renameLocalMenu"
              v-model="value.permissions.scopes"
              :disabled="!canGiveMenuRenaming || disabled"
            />
            <v-checkbox
              label="Allow Local Description Edits"
              :value="customPermissions.editItemDescription"
              v-model="value.permissions.scopes"
              :disabled="!canGiveItemDescriptionEdits || disabled"
            />
            <v-checkbox
              label="Allow Local Menu Calories Edits"
              :value="customPermissions.editCalories"
              v-model="value.permissions.scopes"
              :disabled="!canGiveCaloriesOverride || disabled"
            />
            <v-checkbox
              label="Allow In App Renaming for Items"
              :value="customPermissions.renameLocalItems"
              v-model="value.permissions.scopes"
              :disabled="!canGiveRenameLocalItems || disabled"
            />
            <v-checkbox
              label="Overwrite Local Menu PLU"
              :value="customPermissions.overridePlu"
              v-model="value.permissions.scopes"
              v-if="selectedRole === `${UserRoles.im_user}_role`"
              :disabled="!canGivePluOverride || disabled"
            />
            <v-flex pl-0>
              <div class="Body-1-Black-High-Emphasis-Left">
                Station visibility status
              </div>
              <v-flex xs12 display-flex wrap pl-0>
                <v-checkbox
                  class="mt-0"
                  style="max-width: 120px;"
                  label="Read"
                  :disabled="true"
                  :input-value="true"
                />
                <v-checkbox
                  class="mt-0"
                  style="max-width: 120px;"
                  label="Write"
                  :value="customPermissions.editBrandVisibility"
                  v-model="value.permissions.scopes"
                  :disabled="!canGiveStationVisibilityEdit || disabled"
                />
              </v-flex>
            </v-flex>
          </template>
        </v-flex>
      </v-layout>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import rules from '@/rules';
import {
  UserRoles,
  DefaultPermissions,
  MenuRoles,
  MenuPermissionTypes,
  MenuRolePermissions,
} from '@/constants';

export default {
  props: ['value', 'isActiveUser', 'disabled'],
  data: () => ({
    UserRoles,
    hasCountryCode: false,
    roleSelectionRules: [rules.required('Role is required')],
    emailRules: [rules.required('Email is required'), rules.email('Invalid Email')],
    nameRules: [rules.required('Name is required')],
    passwordRules: [rules.required('Password is required')],
  }),
  computed: {
    ...mapState('adminPanel', {
      currentUser: (state) => state.user,
    }),
    ...mapState('users', ['customPermissions']),
    ...mapGetters('adminPanel', ['isAdmin', 'hasSpecificPermissions', 'hasPermissions']),
    canGiveRefunds() {
      return this.hasPermissions([this.customPermissions.refundOrders]);
    },
    canGiveMenuKick() {
      return this.isAdminRoleSelected && this.hasPermissions([this.customPermissions.kickMenu]);
    },
    canAccessCentricOSReports() {
      return this.hasPermissions([this.customPermissions.canAccessReports]);
    },
    phone: {
      get() {
        const phone = `${this.value.phone || ''}`;
        try {
          if (this.hasCountryCode) return phone.substr(1, phone.length - 1);
          return `${phone}`;
        } catch {
          return phone;
        }
      },
      set(v) {
        let prefix = '';
        if (this.hasCountryCode) prefix = '1';
        this.value.phone = Number(`${prefix}${v}`);
      },
    },
    isAdminRoleSelected() {
      try {
        return (
          this.selectedRole === `${UserRoles.admin}_role` ||
          this.selectedRole === `${UserRoles.sys_admin}_role`
        );
      } catch (err) {
        return false;
      }
    },
    isNewUser() {
      try {
        return !this.$route.params.user_id;
      } catch (err) {
        return true;
      }
    },
    selectedRole() {
      try {
        return (
          this.value.permissions.scopes
            .map((p) => `${p}`.split(':')[0])
            .find((s) => `${s}`.indexOf('_role') > -1) || null
        );
      } catch (err) {
        return null;
      }
    },
    selectedMenuRole() {
      try {
        return (
          this.value.permissions.scopes
            .map((p) => `${p}`.split(':')[0])
            .find(
              (s) =>
                s.includes(MenuRoles.global_menu_group_admin) ||
                s.includes(MenuRoles.local_menu_group_admin) ||
                s.includes(MenuRoles.global_menu_group_manager) ||
                s.includes(MenuRoles.local_menu_group_manager),
            ) || null
        );
      } catch (err) {
        return null;
      }
    },
    roles() {
      const roles = [
        { text: 'Site Manager', value: `${UserRoles.site_operator}_role` },
        { text: 'CDL Delivery Manager', value: `${UserRoles.im_user}_role` },
        { text: 'Runner', value: `${UserRoles.runner}_role` },
      ];
      if (this.isAdmin) {
        roles.push({ text: 'Admin', value: `${UserRoles.admin}_role` });
      }
      return roles;
    },
    menuRoles() {
      if (this.isAdminRoleSelected) {
        return [
          { text: 'Global Menu Group Admin', value: MenuRoles.global_menu_group_admin },
          { text: 'Local Menu Group Admin', value: MenuRoles.local_menu_group_admin },
        ];
      }
      return [
        { text: 'Global Menu Group Manager', value: MenuRoles.global_menu_group_manager },
        { text: 'Local Menu Group Manager', value: MenuRoles.local_menu_group_manager },
      ];
    },
    canAllowStationDeletion() {
      return (
        this.isAdmin || this.hasSpecificPermissions([this.customPermissions.canDeleteStations])
      );
    },
    canGiveMenuAccess() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.menuAccess]);
    },
    canGiveMenuRenaming() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.renameLocalMenu]);
    },
    canGiveItemDescriptionEdits() {
      return (
        this.isAdmin || this.hasSpecificPermissions([this.customPermissions.editItemDescription])
      );
    },
    canGivePluOverride() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.overridePlu]);
    },
    canGiveCaloriesOverride() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.editCalories]);
    },
    canGiveRenameLocalItems() {
      return this.isAdmin || this.hasSpecificPermissions([this.customPermissions.renameLocalItems]);
    },
    canGiveStationVisibilityEdit() {
      return (
        this.isAdmin || this.hasSpecificPermissions([this.customPermissions.editBrandVisibility])
      );
    },
    canGiveViewPaymentIDs() {
      return this.hasPermissions([this.customPermissions.viewPaymentInfo]);
    },
    canGiveWriteDeliveryDestinations() {
      return (
        this.isAdmin ||
        this.hasSpecificPermissions([this.customPermissions.canWriteDeliverLocations])
      );
    },
  },
  watch: {
    value(v) {
      this.$emit('input', v);
    },
  },
  mounted() {
    const phone = `${this.value.phone || ''}`;
    this.hasCountryCode = phone.length > 10;
  },
  methods: {
    selectRole(role) {
      const roleFormatted = `${role}:*:*`;
      const defaultPermissions = DefaultPermissions[role.replace(/_role/, '')] ?? [];
      const defaultAssignablePermissions = defaultPermissions.filter((perm) => {
        return this.hasPermissions([perm]);
      });

      this.$emit('input', {
        ...this.value,
        permissions: { scopes: ['*:*', '*:user', roleFormatted, ...defaultAssignablePermissions] },
      });
    },
    selectMenuRole(role) {
      this.$emit('input', {
        ...this.value,
        permissions: {
          scopes: [
            ...this.value.permissions.scopes.filter(
              (s) =>
                !(
                  s.includes(MenuRoles.global_menu_group_admin) ||
                  s.includes(MenuRoles.local_menu_group_admin) ||
                  s.includes(MenuRoles.global_menu_group_manager) ||
                  s.includes(MenuRoles.local_menu_group_manager) ||
                  s.includes(MenuPermissionTypes.globalMenuGroup) ||
                  s.includes(MenuPermissionTypes.localMenuGroup) ||
                  MenuRolePermissions[role].includes(s)
                ),
            ),
            ...MenuRolePermissions[role],
          ],
        },
      });
    },
  },
};
</script>

<style scoped></style>
