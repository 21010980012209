import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [_vm._v("User Details")])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      items: _vm.roles,
                      label: "Role",
                      value: _vm.selectedRole,
                      placeholder: "Please Select Role",
                      rules: _vm.roleSelectionRules,
                      disabled: _vm.isActiveUser || _vm.disabled
                    },
                    on: { input: _vm.selectRole }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { xs5: "" } })
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "First Name",
                      rules: _vm.nameRules,
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.value.name.first,
                      callback: function($$v) {
                        _vm.$set(_vm.value.name, "first", $$v)
                      },
                      expression: "value.name.first"
                    }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { xs5: "" } })
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "Last Name",
                      rules: _vm.nameRules,
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.value.name.last,
                      callback: function($$v) {
                        _vm.$set(_vm.value.name, "last", $$v)
                      },
                      expression: "value.name.last"
                    }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { xs5: "" } })
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      label: "E-mail",
                      rules: _vm.emailRules,
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.value.email,
                      callback: function($$v) {
                        _vm.$set(_vm.value, "email", $$v)
                      },
                      expression: "value.email"
                    }
                  })
                ],
                1
              ),
              _c(VFlex, { attrs: { xs5: "" } })
            ],
            1
          ),
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(
                    VLayout,
                    [
                      _c(
                        VFlex,
                        { attrs: { xs7: "" } },
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Phone Number",
                              mask: "phone",
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.phone,
                              callback: function($$v) {
                                _vm.phone = $$v
                              },
                              expression: "phone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(VFlex, { attrs: { xs5: "" } })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(VFlex, { attrs: { xs5: "" } })
            ],
            1
          ),
          _vm.selectedRole === _vm.UserRoles.site_operator + "_role" ||
          _vm.isAdminRoleSelected
            ? _c(
                VLayout,
                [
                  _c(
                    VFlex,
                    { attrs: { xs7: "" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          items: _vm.menuRoles,
                          label: "Menu Role",
                          value: _vm.selectedMenuRole,
                          placeholder: "Please Select Role",
                          disabled: _vm.isActiveUser || _vm.disabled
                        },
                        on: { input: _vm.selectMenuRole }
                      })
                    ],
                    1
                  ),
                  _c(VFlex, { attrs: { xs5: "" } })
                ],
                1
              )
            : _vm._e(),
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _vm.selectedRole === _vm.UserRoles.admin + "_role"
                    ? _c(VCheckbox, {
                        attrs: {
                          label: "View Payment IDs",
                          value: _vm.customPermissions.viewPaymentInfo,
                          disabled: !_vm.canGiveViewPaymentIDs || _vm.disabled
                        },
                        model: {
                          value: _vm.value.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.value.permissions, "scopes", $$v)
                          },
                          expression: "value.permissions.scopes"
                        }
                      })
                    : _vm._e(),
                  _vm.selectedRole === _vm.UserRoles.site_operator + "_role"
                    ? _c(VCheckbox, {
                        attrs: {
                          label: "Allow Manage Drop-Off Locations",
                          value: _vm.customPermissions.canWriteDeliverLocations,
                          disabled:
                            !_vm.canGiveWriteDeliveryDestinations ||
                            _vm.disabled
                        },
                        model: {
                          value: _vm.value.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.value.permissions, "scopes", $$v)
                          },
                          expression: "value.permissions.scopes"
                        }
                      })
                    : _vm._e(),
                  _vm.selectedRole !== _vm.UserRoles.runner + "_role"
                    ? _c(VCheckbox, {
                        attrs: {
                          label: "Allow Refunds",
                          value: _vm.customPermissions.refundOrders,
                          disabled: !_vm.canGiveRefunds || _vm.disabled
                        },
                        model: {
                          value: _vm.value.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.value.permissions, "scopes", $$v)
                          },
                          expression: "value.permissions.scopes"
                        }
                      })
                    : _vm._e(),
                  _vm.isAdminRoleSelected
                    ? _c(VCheckbox, {
                        attrs: {
                          label: "Allow Menu Kick-Outs",
                          value: _vm.customPermissions.kickMenu,
                          disabled: !_vm.canGiveMenuKick || _vm.disabled
                        },
                        model: {
                          value: _vm.value.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.value.permissions, "scopes", $$v)
                          },
                          expression: "value.permissions.scopes"
                        }
                      })
                    : _vm._e(),
                  _vm.selectedRole !== _vm.UserRoles.runner + "_role"
                    ? _c(VCheckbox, {
                        attrs: {
                          label: "Allow CentricOS Reports Access",
                          value: _vm.customPermissions.canAccessReports,
                          disabled:
                            !_vm.canAccessCentricOSReports || _vm.disabled
                        },
                        model: {
                          value: _vm.value.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.value.permissions, "scopes", $$v)
                          },
                          expression: "value.permissions.scopes"
                        }
                      })
                    : _vm._e(),
                  _vm.selectedRole === _vm.UserRoles.admin + "_role"
                    ? _c(VCheckbox, {
                        attrs: {
                          label: "Allow Station Deletion",
                          value: _vm.customPermissions.canDeleteStations,
                          disabled: !_vm.canAllowStationDeletion || _vm.disabled
                        },
                        model: {
                          value: _vm.value.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.value.permissions, "scopes", $$v)
                          },
                          expression: "value.permissions.scopes"
                        }
                      })
                    : _vm._e(),
                  _vm.selectedRole === _vm.UserRoles.site_operator + "_role" ||
                  _vm.selectedRole === _vm.UserRoles.im_user + "_role"
                    ? [
                        _c(VCheckbox, {
                          attrs: {
                            label: "Menu Access",
                            value: _vm.customPermissions.menuAccess,
                            disabled: !_vm.canGiveMenuAccess || _vm.disabled
                          },
                          model: {
                            value: _vm.value.permissions.scopes,
                            callback: function($$v) {
                              _vm.$set(_vm.value.permissions, "scopes", $$v)
                            },
                            expression: "value.permissions.scopes"
                          }
                        }),
                        _c(VCheckbox, {
                          attrs: {
                            label: "Allow Renaming in Local Menus",
                            value: _vm.customPermissions.renameLocalMenu,
                            disabled: !_vm.canGiveMenuRenaming || _vm.disabled
                          },
                          model: {
                            value: _vm.value.permissions.scopes,
                            callback: function($$v) {
                              _vm.$set(_vm.value.permissions, "scopes", $$v)
                            },
                            expression: "value.permissions.scopes"
                          }
                        }),
                        _c(VCheckbox, {
                          attrs: {
                            label: "Allow Local Description Edits",
                            value: _vm.customPermissions.editItemDescription,
                            disabled:
                              !_vm.canGiveItemDescriptionEdits || _vm.disabled
                          },
                          model: {
                            value: _vm.value.permissions.scopes,
                            callback: function($$v) {
                              _vm.$set(_vm.value.permissions, "scopes", $$v)
                            },
                            expression: "value.permissions.scopes"
                          }
                        }),
                        _c(VCheckbox, {
                          attrs: {
                            label: "Allow Local Menu Calories Edits",
                            value: _vm.customPermissions.editCalories,
                            disabled:
                              !_vm.canGiveCaloriesOverride || _vm.disabled
                          },
                          model: {
                            value: _vm.value.permissions.scopes,
                            callback: function($$v) {
                              _vm.$set(_vm.value.permissions, "scopes", $$v)
                            },
                            expression: "value.permissions.scopes"
                          }
                        }),
                        _c(VCheckbox, {
                          attrs: {
                            label: "Allow In App Renaming for Items",
                            value: _vm.customPermissions.renameLocalItems,
                            disabled:
                              !_vm.canGiveRenameLocalItems || _vm.disabled
                          },
                          model: {
                            value: _vm.value.permissions.scopes,
                            callback: function($$v) {
                              _vm.$set(_vm.value.permissions, "scopes", $$v)
                            },
                            expression: "value.permissions.scopes"
                          }
                        }),
                        _vm.selectedRole === _vm.UserRoles.im_user + "_role"
                          ? _c(VCheckbox, {
                              attrs: {
                                label: "Overwrite Local Menu PLU",
                                value: _vm.customPermissions.overridePlu,
                                disabled:
                                  !_vm.canGivePluOverride || _vm.disabled
                              },
                              model: {
                                value: _vm.value.permissions.scopes,
                                callback: function($$v) {
                                  _vm.$set(_vm.value.permissions, "scopes", $$v)
                                },
                                expression: "value.permissions.scopes"
                              }
                            })
                          : _vm._e(),
                        _c(
                          VFlex,
                          { attrs: { "pl-0": "" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "Body-1-Black-High-Emphasis-Left"
                              },
                              [
                                _vm._v(
                                  "\n              Station visibility status\n            "
                                )
                              ]
                            ),
                            _c(
                              VFlex,
                              {
                                attrs: {
                                  xs12: "",
                                  "display-flex": "",
                                  wrap: "",
                                  "pl-0": ""
                                }
                              },
                              [
                                _c(VCheckbox, {
                                  staticClass: "mt-0",
                                  staticStyle: { "max-width": "120px" },
                                  attrs: {
                                    label: "Read",
                                    disabled: true,
                                    "input-value": true
                                  }
                                }),
                                _c(VCheckbox, {
                                  staticClass: "mt-0",
                                  staticStyle: { "max-width": "120px" },
                                  attrs: {
                                    label: "Write",
                                    value:
                                      _vm.customPermissions.editBrandVisibility,
                                    disabled:
                                      !_vm.canGiveStationVisibilityEdit ||
                                      _vm.disabled
                                  },
                                  model: {
                                    value: _vm.value.permissions.scopes,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.value.permissions,
                                        "scopes",
                                        $$v
                                      )
                                    },
                                    expression: "value.permissions.scopes"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }