import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("Menu Scheduler")
        ])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(
                VFlex,
                [
                  _c(
                    "div",
                    { staticClass: "Body-1-Black-High-Emphasis-Left" },
                    [_vm._v("\n          Turn Menus On/Off\n        ")]
                  ),
                  _c(
                    VFlex,
                    {
                      attrs: {
                        xs12: "",
                        "display-flex": "",
                        wrap: "",
                        "pl-0": ""
                      }
                    },
                    [
                      _c(VCheckbox, {
                        staticClass: "mt-0",
                        staticStyle: { "max-width": "120px" },
                        attrs: {
                          label: "Read",
                          disabled: true,
                          "input-value": true
                        }
                      }),
                      _c(VCheckbox, {
                        staticClass: "mt-0",
                        staticStyle: { "max-width": "120px" },
                        attrs: {
                          label: "Write",
                          value: _vm.customPermissions.toggleMenuEnabled,
                          disabled: !_vm.canGivetoggleMenuEnabled
                        },
                        model: {
                          value: _vm.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.permissions, "scopes", $$v)
                          },
                          expression: "permissions.scopes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VFlex,
                [
                  _c(
                    "div",
                    { staticClass: "Body-1-Black-High-Emphasis-Left" },
                    [_vm._v("\n          Menu Scheduling\n        ")]
                  ),
                  _c(
                    VFlex,
                    {
                      attrs: {
                        xs12: "",
                        "display-flex": "",
                        wrap: "",
                        "pl-0": ""
                      }
                    },
                    [
                      _c(VCheckbox, {
                        staticClass: "mt-0",
                        staticStyle: { "max-width": "120px" },
                        attrs: {
                          value: _vm.customPermissions.allowReadSchedule,
                          label: "Read",
                          disabled:
                            _vm.permissions.scopes.includes(
                              _vm.customPermissions.allowSchedule
                            ) || !_vm.canGiveAllowReadSchedule
                        },
                        model: {
                          value: _vm.permissions.scopes,
                          callback: function($$v) {
                            _vm.$set(_vm.permissions, "scopes", $$v)
                          },
                          expression: "permissions.scopes"
                        }
                      }),
                      _c(VCheckbox, {
                        staticClass: "mt-0",
                        staticStyle: { "max-width": "120px" },
                        attrs: {
                          label: "Write",
                          value: _vm.customPermissions.allowSchedule,
                          "input-value": _vm.permissions.scopes,
                          disabled: !_vm.canGiveAllowSchedule
                        },
                        on: { change: _vm.toggleMenuScheduleAllowed }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }