<template>
  <v-dialog v-model="dialogProxy" width="914">
    <v-card>
      <v-card-title primary-title style="display: block;">
        <div class="H4-Secondary-Center">Choose Local Menu Groups</div>
        <div class="Body-2-Selected-On-Surface-Medium-Emphasis-Left">
          {{ getSubTitle() }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap justify-space-between>
          <v-flex xs12>
            <v-text-field
              label="Search Local Menu Groups"
              prepend-icon="mdi-magnify"
              v-model="search"
              clearable
            />
          </v-flex>
          <v-flex xs12>
            <v-layout row>
              <v-flex xs6 class="localMenuGroup-panel" style="overflow: hidden;">
                <div class="H6-Secondary-Left">Suggested Local Menu Groups</div>
                <div>
                  <v-list class="local-menu-group-panel" id="local-menu-group-panel">
                    <v-list-tile @click="selectAll">
                      <v-list-tile-content>
                        <v-list-tile-title>Select All</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-for="(localMenuGroup, localMenuGroupIndex) of orderBy(
                        filterBy(allLocalMenuGroups, search, 'name'),
                        'name',
                      )"
                      @click="() => {}"
                      :key="localMenuGroup.id"
                      :id="localMenuGroupIndex"
                    >
                      <v-list-tile-action>
                        <v-checkbox
                          color="#4020ae"
                          v-if="isVisible(localMenuGroupIndex)"
                          :value="localMenuGroup.id"
                          :label="localMenuGroup.name"
                          v-model="selectedLocalMenuGroupsProxy"
                        />
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                </div>
              </v-flex>
              <v-flex xs6>
                <div class="H6-Secondary-Left">
                  Local Menu Groups Selected ({{ selectedLocalMenuGroupsProxy.length }})
                </div>
                <div>
                  <v-list class="local-menu-group-panel">
                    <v-list-tile @click="removeAll">
                      <v-list-tile-content>
                        <v-list-tile-title>Remove All</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                    <v-list-tile
                      v-for="localMenuGroup of orderBy(
                        filterBy(selectedLocalMenuGroupsData, search, 'name'),
                        'name',
                      )"
                      @click="removeBrandGroup(localMenuGroup.id)"
                      :key="localMenuGroup.id"
                    >
                      <v-list-tile-action>
                        <v-icon>mdi-close-circle</v-icon>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>{{ localMenuGroup.name }}</v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="cancel()">Cancel</v-btn>
        <v-btn color="primary" flat @click="confirm()">Continue</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import filters from 'vue2-filters';
import { mapActions } from 'vuex';
import { UserRoles } from '@/constants';

export default {
  mixins: [filters.mixin],
  props: ['dialog', 'selectedLocalMenuGroups'],
  data: () => ({
    dialogProxy: false,
    selectedLocalMenuGroupsProxy: [],
    search: null,
    visibleAreaTop: 0,
    visibleAreaBottom: 550,
    localMenuGroupPanelDOMNode: null,
    saveData: false,
    localMenuGroupDOMNodeHeight: 48,
    listHeight: 450,
    offset: 200,
    UserRoles,
    localMenuGroupMap: {},
  }),
  async mounted() {
    try {
      this.localMenuGroupPanelDOMNode = document.getElementById('local-menu-group-panel');
      this.localMenuGroupPanelDOMNode.addEventListener('scroll', this.handleScroll);
      await this.getLocalMenuGroups();
    } catch (err) {
      console.error(err);
      this.$toast.error('Could not fetch CentricOS Local Menu Groups');
    }
  },
  beforeDestroy() {
    const listPanel = document.getElementById('local-menu-group-panel');
    if (listPanel) listPanel.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    selectedLocalMenuGroupsData() {
      return this.selectedLocalMenuGroupsProxy.map((e) => this.localMenuGroupMap[e]) || [];
    },
    allLocalMenuGroups() {
      return Object.values(this.localMenuGroupMap) || [];
    },
  },
  watch: {
    dialogProxy(d) {
      this.$emit('update:dialog', d);
      // caching everything in hashtable
      if (this.saveData) {
        const selectedLocalMenuGroups = {};
        this.selectedLocalMenuGroupsData.forEach((localMenuGroup) => {
          if (this.selectedLocalMenuGroups[localMenuGroup.id]) {
            selectedLocalMenuGroups[localMenuGroup.id] = this.selectedLocalMenuGroups[
              localMenuGroup.id
            ];
            return;
          }
          selectedLocalMenuGroups[localMenuGroup.id] = localMenuGroup;
          selectedLocalMenuGroups[localMenuGroup.id].isAllSelected = true;
          if (localMenuGroup.brands) {
            Object.keys(localMenuGroup.brands).forEach((brandId) => {
              localMenuGroup.brands[brandId].isSelected = true;
            });
          }
        });
        this.$emit('update:selectedLocalMenuGroups', selectedLocalMenuGroups);
      }
      this.saveData = false;
    },
    dialog(value) {
      this.$set(this, 'dialogProxy', value);
      // converting hashtable into array
      const selectedLocalMenuGroups = Object.keys(this.selectedLocalMenuGroups) || [];
      this.$set(this, 'selectedLocalMenuGroupsProxy', selectedLocalMenuGroups);
    },
  },
  methods: {
    ...mapActions('centricOSMenu', ['getMenuLocalMenuGroups']),
    async getLocalMenuGroups() {
      const results = await this.getMenuLocalMenuGroups({ relationships: '["draft_brands"]' });
      this.localMenuGroupMap = results
        .map((local_menu_group) => ({
          ...local_menu_group,
          brands: local_menu_group.draft_brands.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: cur }),
            {},
          ),
        }))
        .reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    },
    cancel() {
      this.dialogProxy = false;
      this.selectedLocalMenuGroupsProxy = Object.keys(this.selectedLocalMenuGroups) || [];
    },
    confirm() {
      // Have to do this check because radio buttons model the data as a string since only 1 can be selcted
      if (typeof this.selectedLocalMenuGroupsProxy === 'string') {
        this.selectedLocalMenuGroupsProxy = [this.selectedLocalMenuGroupsProxy];
      }
      this.saveData = true;
      this.dialogProxy = false;
    },
    removeBrandGroup(id) {
      this.selectedLocalMenuGroupsProxy.splice(this.selectedLocalMenuGroupsProxy.indexOf(id), 1);
    },
    selectAll() {
      this.search = null;
      this.$set(this, 'selectedLocalMenuGroupsProxy', Object.keys(this.localMenuGroupMap));
    },
    removeAll() {
      this.selectedLocalMenuGroupsProxy = [];
    },
    handleScroll() {
      this.visibleAreaTop = this.localMenuGroupPanelDOMNode.scrollTop - this.offset;
      this.visibleAreaBottom = this.visibleAreaTop + this.listHeight + this.offset;
    },
    isVisible(i) {
      return (
        (i + 1) * this.localMenuGroupDOMNodeHeight >= this.visibleAreaTop &&
        (i + 1) * this.localMenuGroupDOMNodeHeight <= this.visibleAreaBottom
      );
    },
    getSubTitle() {
      return 'Select local menu groups to edit brand permissions.';
    },
  },
};
</script>

<style>
.localMenuGroup-panel {
  min-width: 300px;
}
.local-menu-group-panel {
  height: 50vh;
  overflow: scroll;
  border: solid 1px #757575;
  margin-right: 5px;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
