import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    [
      _c(VCardTitle, { attrs: { "primary-title": "" } }, [
        _c("div", { staticClass: "H5-Primary-Left" }, [
          _vm._v("Smart Analytics")
        ])
      ]),
      _c(
        "div",
        { staticClass: "v-card-content" },
        [
          _c(
            VLayout,
            [
              _c(
                VFlex,
                { attrs: { xs12: "" } },
                [
                  _c(VCheckbox, {
                    attrs: {
                      label: "Has access to Feature",
                      value: "read:analytics:*"
                    },
                    model: {
                      value: _vm.value.permissions.scopes,
                      callback: function($$v) {
                        _vm.$set(_vm.value.permissions, "scopes", $$v)
                      },
                      expression: "value.permissions.scopes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }