import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VDialog,
    {
      attrs: { width: "914" },
      model: {
        value: _vm.dialogProxy,
        callback: function($$v) {
          _vm.dialogProxy = $$v
        },
        expression: "dialogProxy"
      }
    },
    [
      _c(
        VCard,
        [
          _c(
            VCardTitle,
            {
              staticStyle: { display: "block" },
              attrs: { "primary-title": "" }
            },
            [
              _c("div", { staticClass: "H4-Secondary-Center" }, [
                _vm._v("Choose Sites")
              ]),
              _c(
                "div",
                {
                  staticClass: "Body-2-Selected-On-Surface-Medium-Emphasis-Left"
                },
                [_vm._v("\n        " + _vm._s(_vm.getSubTitle()) + "\n      ")]
              )
            ]
          ),
          _c(
            VCardText,
            [
              _c(
                VLayout,
                { attrs: { row: "", wrap: "", "justify-space-between": "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Search Sites",
                          "prepend-icon": "mdi-magnify",
                          clearable: ""
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        VLayout,
                        { attrs: { row: "" } },
                        [
                          _c(
                            VFlex,
                            {
                              staticClass: "site-panel",
                              staticStyle: { overflow: "hidden" },
                              attrs: {
                                xs6:
                                  _vm.selectedRole !==
                                  _vm.UserRoles.runner + "_role",
                                xs12:
                                  _vm.selectedRole ===
                                  _vm.UserRoles.runner + "_role"
                              }
                            },
                            [
                              _c("div", { staticClass: "H6-Secondary-Left" }, [
                                _vm._v("All Sites")
                              ]),
                              _c(
                                "div",
                                [
                                  _c(
                                    VList,
                                    {
                                      staticClass: "list-panel",
                                      attrs: { id: "list-panel" }
                                    },
                                    [
                                      _vm.selectedRole !==
                                      _vm.UserRoles.runner + "_role"
                                        ? _c(
                                            VListTile,
                                            { on: { click: _vm.selectAll } },
                                            [
                                              _c(
                                                VListTileContent,
                                                [
                                                  _c(VListTileTitle, [
                                                    _vm._v("Select All")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        _vm.orderBy(
                                          _vm.filterBy(
                                            _vm.allSites,
                                            _vm.search,
                                            "name"
                                          ),
                                          "name"
                                        ),
                                        function(site, siteIndex) {
                                          return _c(
                                            VListTile,
                                            {
                                              key: site.id,
                                              attrs: { id: siteIndex },
                                              on: { click: function() {} }
                                            },
                                            [
                                              _vm.selectedRole ===
                                              _vm.UserRoles.runner + "_role"
                                                ? _c(
                                                    VListTileAction,
                                                    [
                                                      _c(
                                                        VRadioGroup,
                                                        {
                                                          attrs: {
                                                            mandatory: "",
                                                            multiple: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .selectedSitesProxy[0],
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.selectedSitesProxy,
                                                                0,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "selectedSitesProxy[0]"
                                                          }
                                                        },
                                                        [
                                                          _vm.isVisible(
                                                            siteIndex
                                                          )
                                                            ? _c(VRadio, {
                                                                attrs: {
                                                                  color:
                                                                    "#4020ae",
                                                                  value:
                                                                    site.id,
                                                                  label:
                                                                    site.name
                                                                }
                                                              })
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    VListTileAction,
                                                    [
                                                      _vm.isVisible(siteIndex)
                                                        ? _c(VCheckbox, {
                                                            attrs: {
                                                              color: "#4020ae",
                                                              value: site.id,
                                                              label: site.name
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedSitesProxy,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedSitesProxy = $$v
                                                              },
                                                              expression:
                                                                "selectedSitesProxy"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _vm.selectedRole !== _vm.UserRoles.runner + "_role"
                            ? _c(VFlex, { attrs: { xs6: "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "H6-Secondary-Left" },
                                  [
                                    _vm._v(
                                      "\n                Sites Selected (" +
                                        _vm._s(_vm.selectedSitesProxy.length) +
                                        ")\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      VList,
                                      { staticClass: "list-panel" },
                                      [
                                        _c(
                                          VListTile,
                                          { on: { click: _vm.removeAll } },
                                          [
                                            _c(
                                              VListTileContent,
                                              [
                                                _c(VListTileTitle, [
                                                  _vm._v("Remove All")
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.orderBy(
                                            _vm.filterBy(
                                              _vm.selectedSitesData,
                                              _vm.search,
                                              "name"
                                            ),
                                            "name"
                                          ),
                                          function(site) {
                                            return _c(
                                              VListTile,
                                              {
                                                key: site.id,
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeSite(
                                                      site.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  VListTileAction,
                                                  [
                                                    _c(VIcon, [
                                                      _vm._v("mdi-close-circle")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VListTileContent,
                                                  [
                                                    _c(VListTileTitle, [
                                                      _vm._v(_vm._s(site.name))
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardActions,
            [
              _c(VSpacer),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.confirm()
                    }
                  }
                },
                [_vm._v("Continue")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }